import React from 'react';
import { shape, string } from 'prop-types';
import { Link } from 'gatsby';
import { NavigationContainer } from './NavigationContainer';
import { NavigationList } from './NavigationList';
import { NavigationItem } from './NavigationItem';

export function BlogNavigation({ previous, next }) {
  return (
    <NavigationContainer>
      <h4>Przeczytaj inne artykuły:</h4>
      <NavigationList>
        {previous && (
          <NavigationItem>
            <Link to={previous.fields.slug} rel="prev">
              ← {previous.frontmatter.title}
            </Link>
          </NavigationItem>
        )}
        {next && (
          <NavigationItem>
            <Link to={next.fields.slug} rel="next">
              {next.frontmatter.title} →
            </Link>
          </NavigationItem>
        )}
      </NavigationList>
    </NavigationContainer>
  )
}

const navigationItemPropTypes = shape({
  fields: shape({
    slug: string,
  }).isRequired,
  frontmatter: shape({
    title: string,
  }).isRequired,
});

BlogNavigation.propTypes = {
  previous: navigationItemPropTypes,
  next: navigationItemPropTypes,
};
