import React from 'react';
import { graphql } from 'gatsby';
import { HeaderContainer } from '../../components/HeaderContainer';
import { Layout } from '../../components/Layout/Layout';
import { SeoTags } from '../../components/SeoTags';
import { BlogHeader } from './BlogHeader';
import { BlogNavigation } from './BlogNavigation/BlogNavigation';

export default function BlogPostTemplate({
  data: { site: { siteMetadata: { description } }, post },
  pageContext: { previous, next, slug },
}) {
  const hasVideo = !!post.frontmatter.videoFilename;

  return (
    <Layout description={description}>
      <SeoTags
        title={post.frontmatter.title}
        description={post.excerpt}
        slug={slug}
        featuredImage={post.frontmatter.featuredImage.publicURL}
        publishedTime={post.frontmatter.rawDate}
        isBlogPost
      />
      <HeaderContainer>
        <small>
          {post.frontmatter.date}
        </small>
        <BlogHeader>{post.frontmatter.title}</BlogHeader>
      </HeaderContainer>
      <div dangerouslySetInnerHTML={{ __html: post.html }} />
      {hasVideo && (
        <video width="100%" controls>
          <source src={`../videos/${post.frontmatter.videoFilename}`} type="video/mp4" />
        </video>
      )}
      <BlogNavigation previous={previous} next={next} />
    </Layout>
  )
}

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        description
      }
    }
    post: markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "DD MMMM YYYY", locale: "PL_pl")
        featuredImage {
          publicURL
        }
        rawDate: date
        videoFilename
      }
    }
  }
`
